import React from 'react';
import ReactDOM from 'react-dom';

import styles from './Modal.module.scss';
import IconButton from '../IconButton/IconButton';

const ModalHeader = ({ children }) => (
  <div className={styles.header}>
    <h1 className={styles.title}>{children}</h1>
  </div>
);

ModalHeader.displayName = 'ModalHeader';

const ModalBody = ({ children }) => (
  <div className={styles.body}>
    <div className={styles.body__container}>{children}</div>
  </div>
);

ModalBody.displayName = 'ModalBody';

const ModalFooter = ({ children }) => (
  <div className={styles.footer}>{children}</div>
);

ModalFooter.displayName = 'ModalFooter';

class Modal extends React.Component {
  static defaultProps = {
    isCentered: false,
  };

  static displayName = 'Modal';

  static Header = ModalHeader;
  static Body = ModalBody;
  static Footer = ModalFooter;

  element = document.createElement('div');

  componentDidMount() {
    document.body.appendChild(this.element);
    document.documentElement.classList.add('no-scroll');
  }

  componentWillUnmount() {
    document.body.removeChild(this.element);
    document.documentElement.classList.remove('no-scroll');
  }

  render() {
    const { children, handleClose, title } = this.props;

    return ReactDOM.createPortal(
      <div className={styles.wrapper}>
        <div className={styles.veil} onClick={handleClose} />
        <div className={styles.modal}>
          <div className={styles.close}>
            <IconButton handleClick={handleClose} icon="cross" text="Close" />
          </div>
          {title && (
            <div className={styles.header}>
              <h1 className={styles.title}>{title}</h1>
            </div>
          )}
          <div className={styles.body}>
            {children}
          </div>
        </div>
      </div>,
      this.element
    );
  }
}

export default Modal;
