import React from 'react';
import { Link } from 'gatsby';

import styles from './Footer.module.scss';
import classNames from 'classnames';

const networks = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/needswapgot',
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/needswapgot',
  },
];

const Footer = ({ siteTitle }) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.copyright}>
          © {new Date().getFullYear()} {siteTitle} -{' '}
          <Link className={styles.link} to="/cookie-policy">
            Cookie Policy
          </Link>{' '}
          -{' '}
          <Link className={styles.link} to="/contact">
            Contact
          </Link>
        </div>
        <div className={styles.social}>
          <span className={styles.follow}>Follow us on</span>
          {networks &&
            networks.map(network => (
              <a
                className={classNames(
                  styles.link,
                  styles[network.name.toLowerCase()]
                )}
                key={network.name}
                href={network.url}
              >
                <span className={styles.text}>{network.name}</span>
              </a>
            ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
