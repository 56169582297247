import React, { useState } from 'react';

import { useFirebase } from 'gatsby-plugin-firebase';

import { Link, navigate } from 'gatsby';
import classNames from 'classnames';

import Modal from './../../Modal/Modal';

import styles from './LoggedIn.module.scss';

const LoggedIn = ({ avatar, user }) => {
  const [showModal, setShowModal] = useState(false);
  const [firebase, setFirebase] = useState();

  let handleLogout = async () => {
    firebase.auth().signOut();
    await navigate('/logout');
  };

  useFirebase( firebase => {
    setFirebase(firebase);
  }, []);

  const modal = showModal ? (
    <Modal handleClose={() => setShowModal(false)}>
      <>
        <p>Are you sure you wish to log out?</p>
        <button onClick={() => handleLogout()}>Ok</button>
        <button onClick={() => setShowModal(false)}>Cancel</button>
      </>
    </Modal>
  ) : null;

  return (
    <div className={styles.wrapper}>
      <Link
        aria-label="My Profile"
        className={styles.icon}
        style={{
          backgroundImage: avatar
            ? `url(${avatar.replace('.jpg', '-60w.jpg')})`
            : undefined,
        }}
        to={`/users/${user.slug}`}
      />
      <div className={styles.options}>
        <Link className={styles.userName} to={`/users/${user.slug}`}>
          {user.userName}
        </Link>
        <Link className={styles.actions} to={`/edit-profile`}>
          My Account
        </Link>
        <button
          className={classNames(styles.actions, styles.logout)}
          onClick={() => setShowModal(true)}
        >
          Logout
        </button>
      </div>
      {modal}
    </div>
  );
};

export default LoggedIn;
