import React from 'react';
import classNames from 'classnames';

import styles from './IconButton.module.scss';

const IconButton = ({
  handleClick,
  icon,
  isLarge,
  isLightTheme,
  text,
}) => (
  <button
    aria-label={text}
    className={classNames(
      styles.button,
      isLarge && styles.large,
      isLightTheme ? styles[`${icon}light`] : styles[icon]
    )}
    onClick={handleClick}
    type="button"
  />
);

IconButton.displayName = 'IconButton';

export default IconButton;
