import React from 'react';

import LoggedIn from './LoggedIn/LoggedIn';
import LoggedOut from './LoggedOut/LoggedOut';

import { AuthContext } from '../Authentication/Authentication';

import styles from './Profile.module.scss';

const Profile = ({ ...props }) => {
  return (
    <AuthContext.Consumer>
      { user => (
        <div className={styles.wrapper}>
          {user.isLoggedIn ? (
            <LoggedIn user={user} {...props} />
          ) : (
            <LoggedOut {...props} />
          )}
        </div>
      )}
    </AuthContext.Consumer>

  );
};

Profile.displayName = 'Profile';

export default Profile;
