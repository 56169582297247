import React from 'react';

import { Link } from 'gatsby';

import styles from './LoggedOut.module.scss';

const LoggedOut = ({ location }) => {
  const pageRef = location && location.path ? `?ref=${location.path}` : ``;

  return (
    <Link className={styles.profile} to={`/signin${pageRef}`}>
      <h1 className={styles.signIn}>Sign In</h1>
      <p className={styles.info}>Start tracking your collections today!</p>
    </Link>
  );
};

LoggedOut.defaultProps = {
  tokenExists: false,
};

LoggedOut.displayName = 'LoggedOut';

export default LoggedOut;
