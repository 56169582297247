import React from 'react';
import classNames from 'classnames';

import styles from './NavigationToggle.module.scss';

const NavigationToggle = ({
  isNavOpen,
  toggleNav,
}) => (
  <button className={styles.button} onClick={toggleNav}>
    <div className={classNames(styles.icon, isNavOpen && styles.icon__close)}>
      <span />
      <span />
      <span />
      <span />
    </div>
  </button>
);

export default NavigationToggle;
