import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie';
import { Link } from 'gatsby';

import styles from './CookieBar.module.scss';
import IconButton from '../IconButton/IconButton';

const oneYear = 365 * 24 * 60 * 60;

const CookieBar = () => {
  const cookies = new Cookies();
  const [isVisible, setIsVisible] = useState(cookies.get('show-cookie-bar') !== 'false');

  const handleClose = () => {
    const cookies = new Cookies();
    cookies.set('show-cookie-bar', false, { maxAge: oneYear });
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.cookieContainer}>
      <div className={styles.cookieWrapper}>
        <div className={styles.cookieInfo}>
          <p className={styles.cookieText}>
            We use cookies to enhance your experience and provide additional
            functionality. By continuing to browse the site you are agreeing to the use of cookies.{' '}
            <Link className={styles.cookieLink} to="/cookie-policy">
              More Information
            </Link>
          </p>
        </div>
        <div className={styles.cookieButton}>
          <IconButton handleClick={handleClose} icon="cross" isLarge={true} isLightTheme={true} />
        </div>
      </div>
    </div>
  );
};

CookieBar.propTypes = {
  visible: PropTypes.bool,
};

export default CookieBar;
