import React from 'react';

import { Link, graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Header.module.scss';
import Profile from '../Profile/Profile';
import Navigation from '../Navigation/Navigation';
import Search from '../Search/Search';

const Header = ({
  isNavOpen,
  location,
  pageHasHero,
  siteTitle,
  toggleNav,
  user,
}) => {
  const { siteSearchIndex } = useStaticQuery(graphql`
      query SearchIndexQuery {
          siteSearchIndex {
              index
          }
      }
  `);

  return (
    <div className={classNames(styles.banner, pageHasHero && styles.hero)}>
      <header className={styles.wrapper}>
        <Link className={styles.link} to="/">
          <div className={styles.logo}>
          <span className={classNames(styles.card, styles.need)}>
            <span className={styles.bg} />
            <span className={styles.letter}>N</span>
          </span>
            <span className={classNames(styles.card, styles.swap)}>
            <span className={styles.bg} />
            <span className={styles.letter}>S</span>
          </span>
            <span className={classNames(styles.card, styles.got)}>
            <span className={styles.bg} />
            <span className={styles.letter}>G</span>
          </span>
          </div>
          <h1 className={styles.header}>{siteTitle}</h1>
        </Link>
        {process.env.NODE_ENV === 'development' && (
          <Profile location={location} />
        )}
      </header>
      <div className={styles.navigation}>
        <div className={styles.navigationWrapper}>
          <div className={classNames(styles.item, styles.itemMenu)}>
            <Navigation
              isNavOpen={isNavOpen}
              location={location}
              menu={[
                {
                  key: 'home',
                  path: '/',
                  title: 'Home',
                },
                {
                  key: 'collections',
                  path: '/collections',
                  title: 'Collections',
                },
                /*{
                  key: 'features',
                  path: '/features',
                  title: 'Features',
                },
                {
                  key: 'tags',
                  path: '/tags',
                  title: 'Tags',
                },*/
              ]}
              toggleNav={toggleNav}
            />
          </div>
          {/*<div className={classNames(styles.item, styles.itemSearch)}>
            <Search searchIndex={siteSearchIndex.index} />
          </div>*/}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
