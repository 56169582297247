import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Authentication from '../Authentication/Authentication';
import Analytics from '../Analytics/Analytics';
import CookieBar from '../CookieBar/CookieBar';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Seo from '../../Seo';

// import global styles
import '../../../styles/global/styles.scss';

import styles from './Layout.module.scss';

const Layout = ({ children, hero, location, pageTitle, shareImage }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleNav = () => setIsNavOpen(!isNavOpen);
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const handleClose = () => {
    setIsNavOpen(false);
    setIsSearchOpen(false);
  };

  const siteTitle = process.env.SITE_TITLE || 'Need Swap Got';

  return (
    <>
      <div
        className={classNames(
          styles.container,
          isNavOpen && styles.nav,
          isSearchOpen && styles.search
        )}
      >
        <Seo title={pageTitle} image={shareImage} />
        <Analytics location={location} pageTitle={pageTitle} />
        <Authentication>
          <Header
            isNavOpen={isNavOpen}
            isSearchOpen={isSearchOpen}
            location={location}
            pageHasHero={!!hero}
            siteTitle={siteTitle}
            toggleNav={toggleNav}
            toggleSearch={toggleSearch}
          />
          <main
            className={classNames(
              styles.main,
              (isNavOpen || isSearchOpen) && styles.fade
            )}
            onClick={handleClose}
          >
            {hero}
            <div className={styles.content}>
              {children}
            </div>
          </main>
          <Footer siteTitle={siteTitle} />
        </Authentication>
      </div>
      <CookieBar />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hero: PropTypes.node,
  location: PropTypes.shape({
    action: PropTypes.string,
    pathname: PropTypes.string,
  }),
  pageTitle: PropTypes.string.isRequired,
  shareImage: PropTypes.string,
};

export default Layout;
