import React from 'react';
import PropTypes from 'prop-types';
import { useFirebase } from "gatsby-plugin-firebase";

const trackPageView = (firebase, location, title) => {
  if (process.env.GATSBY_ENVIRONMENT === 'production') {
    const analytics = firebase.analytics();

    if (location.action === 'PUSH') {
      analytics.logEvent('page_view', {
        page_title: title,
      });
    }
  } else {
    console.info(`Logged page_view for "${title}"`)
  }
};

const Analytics = ({ location, pageTitle }) => {
  useFirebase(firebase => {
    trackPageView(firebase, location, pageTitle);
  }, []);

  return null;
};

Analytics.propTypes = {
  location: PropTypes.shape({
    action: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageTitle: PropTypes.string.isRequired,
};

export default Analytics;
