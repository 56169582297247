import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useFirebase } from 'gatsby-plugin-firebase';

const defaultValue = { isLoggedIn: false, isDefault: true };

export const AuthContext = React.createContext(defaultValue);

const Authentication = ({ children }) => {
  const [user, setUser] = useState(defaultValue);

  useFirebase(firebase => {
    const auth = firebase.auth();
    const db = firebase.firestore();

    const unsubscribe = auth.onAuthStateChanged(async user => {
      let result = { ...defaultValue };

      if (user) {
        const userData = await db
          .collection('users')
          .doc(user.uid)
          .get();

        if (!userData.data().userName) {
          await navigate('/add-username');
        }

        result = { id: user.uid, isLoggedIn: true, ...userData.data() };
      }

      setUser(result);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={user}>
      { children }
    </AuthContext.Provider>
  );
};

export default Authentication;
